<template lang="pug">
  .ValidationData
    p.text-center.text-primary.font-semibold.mt-8.mb-3
      | Validación de datos personales
    article(v-if="serviceLine")
      p.text-sm.text-info_color.mx-auto.max-w-xs.text-center
        | Si encuentras diferencias en tus datos
      p.text-sm.text-info_color.mx-auto.max-w-xs.text-center
        | comunicate en {{ userData.cityOrMunicipio }} a la línea
        a(:href="`tel:+51${serviceLine}`").text-secondary.ml-1 {{ serviceLine }}
    form(
      @submit.prevent="handleContinue"
    )#formUserValidation.userData.mx-5
      input-component(
        label="Nombre"
        v-model="userName"
        id="userName"
        name="userName"
        type="text"
        mode="input"
        readonly
        :disabled="loadingTexts"
      )
      input-component(
        label="Correo electrónico"
        v-model="email"
        id="email"
        name="email"
        type="text"
        mode="input"
        required
        autocapitalize="off"
        :disabled="loadingTexts"
      )
      input-component(
        label="Teléfono"
        v-model="phone"
        id="phone"
        name="phone"
        :type="typeInpu"
        mode="input"
        :disabled="loadingTexts"
      )
      input-component(
        label="Dirección"
        v-model="address"
        id="address"
        name="address"
        type="text"
        mode="input"
        :disabled="loadingTexts"
      )
    footer.Footer.fixed.p-5.pt-2.max-w-lg.w-full.bg-white
      button-component(
        :text="btn_text"
        themeSelected="secondary"
        form="formUserValidation"
        type="submit"
        :disabled="disabledContinue"
      )
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import asterisker from "@/helpers/asteristick.js";
import loadingMessage from "../utils/loadingMessage";
// import LS from "../helpers/localStorage";

export default {
  name: "ValidationData",
  data() {
    return {
      userName: "",
      address: "",
      email: "",
      phone: "",
      loadingTexts: true,
      disabledContinue: false,
      btn_text_defatul: 'Continuar',
      btn_text: 'Continuar',

      email_changed: "",
      address_changed: "",
      phone_changed: "",

      typeInpu: 'text'
    };
  },
  async created() {

    // get appointments
    this.btn_text = 'Cargando datos...';
    this.handleShowModal(loadingMessage({
      title: "Cargando datos...",
      state: "loading",
    }));

    // fetch all users from mysql
    await this.getUsers({
      document: this.userData.documentNumber
    });
    this.btn_text = 'Continuar';
    this.closeModal();
    
    // userList is from mysql
    // userData is local
    let user = this.userList.filter(u=>u.document == this.userData.documentNumber)

    if(!this.userData){

      if(user){
        user = user[0];
        this.changeUserData({
          dcType: user.dcType,
          documentType: user.documentType,
          documentNumber: user.documentNumber,
          cityOrMunicipio: user.cityOrMunicipio,
          cityId: user.cityId,
          confirmCafam: user.confirmCafam,
          ...user,
        });
      }

    }else{
      
      // user = user.find(u=>u.documentNumber == this.userData.documentNumber);
      if(user && user.length > 0){
        
        user = user[0];
        let userDataLocal;
        if(user.name != this.userData.name) {
          userDataLocal = {
            id:  user.id,
          }
        } else {
          userDataLocal = {
            id:  user.id,
            dcType: this.userData.dcType,
            documentType: this.userData.documentType,
            documentNumber: user.document,
            cityOrMunicipio: this.userData.cityOrMunicipio,
            cityId: this.userData.cityId,
            confirmCafam: user.confirmCafam,
            ...user,
          }
        }

        this.changeUserData(userDataLocal);
        // this.setState({ key: "userData", value: user});

      }else{
        
        // without localstorage
        this.changeUserData({
          dcType: this.userData.dcType,
          documentType: this.userData.documentType,
          documentNumber: this.userData.documentNumber,
          cityOrMunicipio: this.userData.cityOrMunicipio,
          cityId: this.userData.cityId,
          confirmCafam: this.userData.confirmCafam,
          email: this.email,
          phone: this.phone,
          address: this.address
        });

      }
        
    }
    
    this.userName = this.nameData;
    this.address = this.addressData;
    this.email = this.emailData;
    this.phone = this.phoneData;
    this.address_changed = this.addressData;
    this.email_changed = this.emailData;
    this.phone_changed = this.phoneData;
    
  },
  mounted(){
    setTimeout(() => {
      
      var phoneInp = document.getElementById("phone");
      phoneInp.addEventListener("focus", ( event ) => {
        event.target.type = 'number';
      }, true);

      phoneInp.addEventListener("blur", ( event ) => {
        event.target.type = 'text';
      }, true);

    }, 1000);
  },
  components: {
    ButtonComponent: () => import("../components/general/Button.vue"),
    InputComponent: () => import("../components/general/Input.vue"),
  },
  watch: {
    showModal(val) {
      if (!val) {
        this.disabledContinue = false;
      }
    },
    userName(val) {
      if (val) {
        this.loadingTexts = false;
      }
    },
  },
  computed: {
    ...mapState({
      userData: (state) => state.root.userData,
      user: (state) => state.user.userData,
      closeModal: (state) => state.root.closeModal,
      showModal: (state) => state.root.showModal,
      appointments: (state) => state.appointments.appointments,
      env: (state) => state.environment.environment.env,
      userList: (state) => state.environment.userList // from mysql
    }),
    serviceLine() {
      return this.userData.serviceLine;
    },
    phoneData() {
      let pure = this.userData.phone ? `${this.userData.phone}` : "";
      return asterisker(String(pure), 0, 4);
    },
    addressData() {
      let pure = this.userData.address || "";
      return asterisker(pure, 0, pure.length / 2.5);
    },
    nameData() {
      let partName = null;
      let partLastName = null;
      let splittedName = this.userData.name.split(" ").filter((item) => item !== "");
      
      if (splittedName.length > 2) {
        let firstName = asterisker(splittedName[0], 0, splittedName[0].length / 2);
        let secondName = asterisker(splittedName[1], 0, splittedName[1].length / 2);
        let filteredOthers = splittedName.filter((sn, index) => {
          return index !== 1 && index !== 0;
        });
        partName = firstName + " " + secondName + " " + filteredOthers.toString().replaceAll("," , " ");
        if (this.userData.lastname) {
          partLastName = asterisker(this.userData.lastname, 0, this.userData.lastname.length / 2);
        }
      } else {
        partName = asterisker(this.userData.name, 0, this.userData.name.length - (splittedName[0].length / 2));
        partLastName = this.userData.lastname;
      }

      return `${partName} ${partLastName || ""}`;
    },
    emailData() {
      let pure = this.userData.email || "";
      let splited = pure.split("@");
      let splitedPoint = splited[1] ? splited[1].split(".") : 0;

      return `${asterisker(splited[0], 0, splited[0].length / 2)}${
        splited[1] ? "@" + asterisker(splitedPoint[0], 0, Math.floor(splitedPoint[0].length / 2)) : ""
      }${
        splited[1] ? "." + asterisker(splitedPoint[1], 0, Math.floor(splitedPoint[1].length / 2)) : ""
      }`;
    }
  },
  methods: {
    ...mapActions("root", [
      "changeTrip",
      "handleShowModal",
      "changeUserData",
    ]),
    ...mapActions(["fetchAppointments", "getUsers"]),
    // ...mapActions("user", [
    //   "getUserInformation",
    // ]),
    ...mapMutations("user", [
      "setState",
    ]),
    async handleContinue() {
      try {
        // eslint-disable-next-line no-useless-escape
        const regexEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        this.disabledContinue = true;
        this.handleShowModal(loadingMessage({
          title: "Consultando información del usuario...",
          state: "loading",
        }));
        // await this.getUserInformation();

        if (
          this.address ||
          this.email ||
          this.phone
        ) {
          if (
            // !this.userName ||
            // !this.address ||
            // !this.email ||
            !this.phone ||
            (this.email && !this.email.includes("*") ? !regexEmail.test(this.email) : false)
          ) {
            this.handleShowModal({
              showModal: true,
              modalState: {
                buttonText: "Entendido",
                disabledContinue: false,
                showModal: true,
                responseState: "danger",
                responseToContinueTitle: "Error al digitar tus datos",
                responseToContinue: `
                  Por favor llena los datos de una forma adecuada.
                `
              },
            });
            console.error("Error al digitar datos, falta información");
            return;
          }
        }

        this.closeModal();
        
        //  only on storage user
        this.setState({ key: "userData", value: {
          address: this.address == this.address_changed ? this.user.address : this.address,
          email: this.email == this.email_changed ? this.user.email : this.email.toLowerCase(),
          phone: this.phone == this.phone_changed ? this.user.phone : this.phone
        }});

        // get appointments
        this.btn_text = 'Buscando citas...';
        this.handleShowModal(loadingMessage({
          title: "Buscando citas...",
          state: "loading",
        }));
        let queryData = '?customer.dni='+ this.userData.documentNumber + "&status=CONFIRMED";
        await this.fetchAppointments({
          url: this.env.VUE_APPOINTMENTS_DEBMEDIA_SERVER,
          headers: {
            'token_appointments': this.env.VUE_APPOINTMENTS_DEBMEDIA_API_KEY,
          },
          queryData: queryData
        });
        this.closeModal();
        this.btn_text = this.btn_text_defatul;
        
        if(this.appointments.length > 0){
          return this.changeTrip('Home_dates');
        }

        // if (this.user.dates && this.user.dates.length) {
        //   this.changeTrip("Home_dates");
        //   return;
        // }
        this.changeTrip("FormDocumentsAdd");
      } catch (error) {
        console.error("[error: Falla en el servicio]:", error);
        this.handleShowModal(loadingMessage({
          title: "Lo sentimos ocurrió un error al consultar tu información",
          state: "danger",
        }));
      }
    },
  },
}
</script>

<style scoped>
</style>